<template>
  <div>
    <Navbar @lang="getLang" color="white" />
    <iframe
      :src="
        lang === 'id'
          ? 'https://new.higo.id/integrated-digital-agency'
          : 'https://new.higo.id/en/integrated-digital-agency'
      "
      frameborder="0"
      class="react-iframe-container"
    ></iframe>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
export default {
  data() {
    return {
      lang: "",
    };
  },
  methods: {
    getLang(value) {
      this.lang = value;
    },
  },
  name: "IntegratedDigitalAgency",
  metaInfo: {
    title: "Integrated Digital Agency",
    meta: [
      {
        name: "description",
        content:
          "Integrated Digital Agency Page",
      },
    ],
  },
  components: {
    Navbar,
  },
};
</script>

<style>
.react-iframe-container {
  display: block;
  height: 100vh;
  width: 100vw;
}
</style>
